export default {

    // faqs selected themes

    init() {

        const questionFaq = document.getElementsByClassName("question-faq");

        if (questionFaq) { 
            const questionFaqClick = (eventHappened) => {
                let targetClicked = eventHappened.currentTarget;
                // element open
                const description = targetClicked.nextElementSibling;
                // rotate Icon
                targetClicked.querySelector(".add-icon").classList.toggle("rotated");
                targetClicked.classList.add("active");
        
                if (description.style.maxHeight) {
                    description.style.maxHeight = null;
                }
                // others
                else {
                    const allDescriptions = document.getElementsByClassName("answer-faq");
                    const allQuestions = document.getElementsByClassName("question-faq");
                    for (let i = 0; i < allQuestions.length; i++) {
                        allQuestions[i].querySelector(".add-icon").classList.remove("rotated");
                    }
                    for (let i = 0; i < allDescriptions.length; i++) {
                        if (allDescriptions[i].style.maxHeight) {
                            allDescriptions[i].style.maxHeight = null;
                        }
                    }
                    //rotate Icon
                    targetClicked.querySelector(".add-icon").classList.toggle("rotated");
                    description.style.maxHeight = description.scrollHeight + "px";
                }
            }
            
            for (let i = 0; i < questionFaq.length; i++) {
                questionFaq[i].addEventListener("click", function (event) {
                    questionFaqClick(event);

                });
            }
            
            const faqButtons = document.getElementsByClassName("faq-switch");

            for (let i = 0; i < faqButtons.length; i++) {
                faqButtons[i].addEventListener("click", function (event) {
                    
                    for (let i = 0; i < faqButtons.length; i++) {
                        const button = faqButtons[i];
                        const topicId = button.dataset.topicId;
                        const topicColorClass = button.dataset.topicColorClass;
                        button.classList.remove(topicColorClass);
                        button.classList.remove("text-white");
                        button.classList.add("text-primary");
                        const faqContainers = document.querySelectorAll("[data-faq-topic-id='"+topicId+"']")
                        faqContainers.forEach(element => element.classList.add("hidden"));
                    }
                    const currentTopicId = event.currentTarget.dataset.topicId;
                    const currentTopicColorClass = event.currentTarget.dataset.topicColorClass;
                    event.currentTarget.classList.add(currentTopicColorClass);
                    event.currentTarget.classList.add("text-white");
                    event.currentTarget.classList.remove("text-primary");
                    const faqContainers = document.querySelectorAll("[data-faq-topic-id='"+currentTopicId+"']")
                    faqContainers.forEach(element => element.classList.remove("hidden"));
                });
                // preselect first Button
                if (i === 0) {
                    faqButtons[i].click();
                    faqButtons[i].focus();
                }
            }
            

        }

    },
};