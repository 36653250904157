// import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import { enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import anime from "animejs";

export default {
    init() {

        //////////// Navigation
        const menu = document.getElementById("menu");
        const header = document.querySelector(".header-menu");
        const hamburger = document.getElementById("hamburger");
        const logoSeeseitn = document.getElementById("logo-theme");
        const mapDivHeader = document.getElementById("map-div-header");
        const footer = document.getElementById("footer");
        const map = document.getElementById("map");
        const mapIconBlack = document.getElementById("mapicon-black");
        const body = document.querySelector("body");
        // const menuIcon = document.getElementsByClassName("menu-icon");

        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty("--vh", `${vh}px`);

        // We listen to the resize event
        window.addEventListener("resize", () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            // if (menu.classList.contains("open")) {
            //     menu.classList.remove("open");
            //     hamburger.classList.remove("close");
            //     header.classList.remove("open-menu");
            //     body.classList.remove("overflow-hidden");
            //     body.classList.remove("fixed");
            // }
        });

        if (hamburger) {
            hamburger.addEventListener("click", function () {

                const timeline = anime.timeline({ duration: 400, easing: "easeOutQuint" });

                // close menu
                if (menu.classList.contains("open")) {
                    // enableBodyScroll(content);
                    menu.classList.remove("open");
                    header.classList.remove("open-header-menu");
                    hamburger.classList.remove("close");
                    hamburger.classList.remove("fixed");
                    logoSeeseitn.style.display = "block";
                    mapDivHeader.style.display = "none";
                    footer.style.display = "block";
                    if (map) {
                        map.style.display = "block";
                    }
                    mapIconBlack.style.display = "block";
            

                    timeline.add({
                        targets: "#menu",
                        opacity: 0,
                        complete: () => {
                            clearAllBodyScrollLocks();
                            body.classList.remove("overflow-hidden");
                            body.classList.remove("fixed");
                            body.classList.add("relative");
                        },
                    });

                } else {
                    // open menu
                    // disableBodyScroll(content);
                    enableBodyScroll(header);
                    menu.classList.add("open");
                    header.classList.add("open-header-menu");
                    hamburger.classList.add("close");
                    hamburger.classList.add("fixed");
                    logoSeeseitn.style.display = "none";
                    mapDivHeader.style.display = "flex";
                    mapDivHeader.style.alignItems="center";
                    footer.style.display = "none";
                    if (map) {
                        map.style.display = "none";
                    }
                    mapIconBlack.style.display = "none";

                    timeline.add({
                        targets: "#menu",
                        opacity: 1,
                        complete: () => {
                            body.classList.add("overflow-hidden");
                            body.classList.remove("relative");
                            body.classList.add("fixed");
                        },
                    });
                }
            });
        }
    },
}