import "intersection-observer";
import swiper from "./swiper";
import lazyload from "./dal-lazyload";
import faq from "./faq";
import event from "./events";
import contact from "./contact";
import navigation from "./navigation";
import eventOverview from "./event-overview";
import cookieBanner from "./cookie-banner";
import AOS from "aos";
import "lazysizes";
// eslint-disable-next-line no-undef
require("./autoload"); //enforce to copy all images of src into web folder 

(function () {

	lazyload.init();

	faq.init();

	navigation.init();

	eventOverview.init();

	event.init();

	contact.init();

	AOS.init();
	
	//Init all swiper if there are some
	let allSwiper = document.querySelectorAll(".swiper-container");
	if (allSwiper.length) {
		allSwiper.forEach(function (swiperEle) {
			//Set swiper element and swiper options from data-attribute
			swiper.init(swiperEle, JSON.parse(swiperEle.dataset.swiperOptions));
		})
	}

	// remove comment if you want to add a cookie banner
	cookieBanner.init(); 
})();
