export default {

    // filter event categories

    init() {
        
        const eventButtons = document.getElementsByClassName("event-switch");

        for (let i = 0; i < eventButtons.length; i++) {
            // eventButtons[i].addEventListener("click", function (event) {
                
            //     for (let i = 0; i < eventButtons.length; i++) {
            //         const button = eventButtons[i];
            //         const topicId = button.dataset.topicId;
            //         const topicColorClass = button.dataset.topicColorClass;
            //         button.classList.remove(topicColorClass);
            //         button.classList.remove("text-white");
            //         button.classList.add("text-primary");
            //         const eventContainers = document.querySelectorAll("[data-event-topic-id='"+topicId+"']")
            //         eventContainers.forEach(element => element.classList.add("hidden"));
            //     }
            //     const currentTopicId = event.currentTarget.dataset.topicId;
            //     const currentTopicColorClass = event.currentTarget.dataset.topicColorClass;
            //     event.currentTarget.classList.add(currentTopicColorClass);
            //     event.currentTarget.classList.add("text-white");
            //     event.currentTarget.classList.remove("text-primary");
            //     const eventContainers = document.querySelectorAll("[data-event-topic-id='"+currentTopicId+"']")
            //     eventContainers.forEach(element => element.classList.remove("hidden"));
            // });

            
            
            // preselect first Button

            // const allEventContainers = document.getElementsByClassName("event-container");
            // if (i === 0) {
            //     eventButtons[i].click();
            //     eventButtons[i].focus();
            //     allEventContainers.forEach(element => element.classList.remove("hidden"));
            // }
            
            // const eventButtonSeeseitn = document.getElementsByClassName("data-topic-id-8");
            // eventButtonSeeseitn.addEventListener("click", function() {
            //     allEventContainers.forEach(element => element.classList.remove("hidden"));
            // });
        }

    },
};