export default {

    init() {

        // Fetch the form we want to deal with
        const forms = document.getElementsByTagName("form");

        forms.forEach(form => {
            // Find the CSRF token hidden input, so we can replace it
            const csrfInput = form.querySelector("input[name=\"CRAFT_CSRF_TOKEN\"]");
            if (csrfInput) {

                // Fetch the new token for the form and replace the CSRF input with our new one
                fetch("/actions/formie/csrf/input")
                    .then(result => { return result.text(); })
                    .then(result => { csrfInput.outerHTML = result; });
            }
        });
    },
};
